/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "스탠다드아웃 96번째 로그에서는 Next.js 컨퍼런스, 아이맥 27' 2020, 쿠버네티스 1.19 출시 등에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.h3, null, "당근마켓 라이브"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/1146"
  }, "당근마켓 라이브")), "\n"), "\n", React.createElement(_components.h3, null, "next.js 컨퍼런스"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://nextjs.org/conf"
  }, "Next.js Conf")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/search?q=next.js%20ticket&src=typed_query"
  }, "next.js ticket - Twitter Search / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://post.naver.com/viewer/postView.nhn?volumeNo=17138507&memberNo=1834"
  }, "애플페이는 못 써도... 각종 멤버십 카드 애플 월렛 Wallet 추가 후 사용하는 방법 : 네이버 포스트")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/"
  }, "Festa! 이벤트를 만나는 가장 쉬운 방법")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://nestjs.com/"
  }, "NestJS - A progressive Node.js framework")), "\n"), "\n", React.createElement(_components.h3, null, "아이맥 27' 2020 출시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr/imac/"
  }, "iMac - Apple (KR)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/applekorea_rra"
  }, "애플코리아 전파인증 현황 (@applekorea_rra) / Twitter")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.apple.com/kr-k12/shop"
  }, "교육 특가 및 학생 할인 - 교육 - Apple (KR)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.macrumors.com/2020/09/18/2020-imac-graphics-glitches/"
  }, "Graphical Glitches Plaguing Some 2020 iMac Owners With Radeon Pro 5700 XT GPU - MacRumors")), "\n"), "\n", React.createElement(_components.h3, null, "타입스크립트 4.0"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devblogs.microsoft.com/typescript/announcing-typescript-4-0/"
  }, "Announcing TypeScript 4.0 | TypeScript")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://devblogs.microsoft.com/typescript/announcing-the-new-typescript-website/"
  }, "Announcing the new TypeScript Website | TypeScript")), "\n"), "\n", React.createElement(_components.h3, null, "쿠버네티스 1.19"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.aws.amazon.com/ko_kr/eks/latest/userguide/kubernetes-versions.html"
  }, "Amazon EKS Kubernetes 버전 - Amazon EKS")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kubernetes.io/docs/setup/release/notes/"
  }, "v1.19 Release Notes | Kubernetes")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://kubernetes.io/blog/2020/08/26/kubernetes-release-1.19-accentuate-the-paw-sitive/"
  }, "Kubernetes 1.19: Accentuate the Paw-sitive | Kubernetes")), "\n"), "\n", React.createElement(_components.h3, null, "크롬 그룹 탭 기능 추가"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://blog.google/products/chrome/manage-tabs-with-google-chrome/"
  }, "Organize your tabs with tab groups in Google Chrome")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://addons.mozilla.org/en-US/firefox/addon/tree-style-tab/"
  }, "Tree Style Tab – Get this Extension for 🦊 Firefox (en-US)")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.theverge.com/2020/3/30/21199376/microsoft-365-subscriptions-office-family-safety-app-teams-features-price"
  }, "Microsoft aims to win back consumers with new Microsoft 365 subscriptions - The Verge")), "\n"), "\n", React.createElement(_components.h3, null, "AWS Controllers for Kubernetes 출시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://aws.amazon.com/blogs/containers/aws-controllers-for-kubernetes-ack/"
  }, "Introducing the AWS Controllers for Kubernetes (ACK) | Containers")), "\n"), "\n", React.createElement(_components.h3, null, "데이터독, 안드로이드 RUM 모니터링 출시"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.datadoghq.com/blog/datadog-mobile-rum/"
  }, "Improve Mobile User Experience With Datadog Mobile Real User Monitoring | Datadog")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
